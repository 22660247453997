import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hello. I have been forgotten for a while, and now I am being worked
          on. Check back later!
        </p>
        <p>
          With love,
          <br></br>
          Your favourite website.
        </p>
      </header>
    </div>
  );
}

export default App;